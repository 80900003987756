.hero {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px 20px;
    background-color: #333;
    color: #ffffff;
    height: 70vh;
    width: 95%; /* Make the hero section take 95% of the width */
    margin: 0 auto; /* Center the hero section horizontally */
    border-radius: 10px; /* Adjust this value to control roundness */
    position: relative;
    overflow: hidden;
}

.hero-content {
    max-width: 600px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-left: 50px; /* Add margin-left to adjust the left spacing */
}

.hero-content h1 {
    font-size: 40px;
    margin-bottom: 5px;
    font-family: 'Nunito', sans-serif;
    margin-top: 40px; /* Adjust margin-top to lower the main heading */
}

.subheading {
    font-size: 24px;
    margin-bottom: 30px;
    font-family: 'Roboto', sans-serif;
}

.hero-buttons {
    display: flex;
    flex-direction: column; /* Stack buttons vertically */
    gap: 20px;
    max-width: 95%; /* Ensure buttons do not exceed the screen width */
    box-sizing: border-box; /* Include padding and border in element's total width and height */
    padding: 0 20px; /* Add padding to ensure space on the sides */
}

.primary-btn, .secondary-btn {
    padding: 15px 30px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-family: 'Nunito', sans-serif;
    width: 100%; /* Make buttons take full width */
    max-width: 100%; /* Ensure buttons do not exceed the screen width */
    box-sizing: border-box; /* Include padding and border in element's total width and height */
}

.primary-btn {
    background-color: #ff0;
    color: #000;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.primary-btn:hover {
    background-color: #ffd700;
    transform: scale(1.05);
}

.secondary-btn {
    background-color: transparent;
    color: #ff0;
    border: 1px solid #ff0;
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
}

.secondary-btn:hover {
    background-color: #ff0;
    color: #000;
    transform: scale(1.05);
}

.hero-image {
    width: 40%;
    display: flex;
    justify-content: flex-end;
    margin-right: 10%; /* Add margin-right to adjust the right spacing */
}

.hero-image img {
    width: 100%;
    height: auto;
    border-radius: 10px;
}

@media (max-width: 768px) {
    .hero {
        flex-direction: column;
        align-items: center;
        height: auto; /* Adjust height for mobile */
        padding: 30px 20px;
    }

    .hero-content {
        text-align: center;
        margin-left: 0; /* Center content on mobile */
    }

    .hero-content h1 {
        font-size: 36px; /* Smaller font size for mobile */
    }

    .subheading {
        font-size: 18px; /* Smaller font size for mobile */
    }

    .hero-buttons {
        flex-direction: column;
        gap: 10px; /* Adjust gap for mobile */
        width: 100%; /* Ensure buttons container does not exceed screen width */
        padding: 0 20px; /* Add padding to ensure space on the sides */
    }

    .primary-btn, .secondary-btn {
        width: 100%; /* Ensure buttons take full width on mobile */
        max-width: 100%; /* Ensure buttons do not exceed screen width */
    }

    .hero-image {
        display: none; /* Hide image on mobile */
    }
}
