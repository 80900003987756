/* src/components/BlogDetails.css */
.blog-details {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    background-color: #262626;
    border-radius: 10px;
  }
  
  .blog-details h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .blog-details-image {
    width: 100%;
    max-height: 400px;
    object-fit: cover;
    border-radius: 10px;
  }
  
  .blog-content {
    margin-top: 20px;
    line-height: 1.6;
  }
  