.services {
    padding: 50px 20px;
    background-color: #2e2e2e; /* Same background color as other sections */
    color: #fff; /* Text color to match the dark background */
    text-align: center;
    width: 90%;
    margin: 0 auto;
    border-radius: 15px;
    margin-bottom: 20px;
    margin-top: 10px;
}

.services h2 {
    font-size: 36px;
    margin-bottom: 40px;
    font-family: 'Nunito', sans-serif;
}

.service-items {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 20px;
}

.service-item {
    background-color: #3c3c3c; /* Slightly lighter dark background for the service items */
    padding: 20px;
    border-radius: 15px;
    width: 22%;
    text-align: center;
    box-sizing: border-box;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service-item:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.service-image {
    width: 100%;
    height: auto;
    border-radius: 15px;
}

.service-content {
    padding: 10px;
}

.service-content h3 {
    font-size: 24px;
    margin: 10px 0;
    font-family: 'Nunito', sans-serif;
    color: #fff;
}

.service-content p {
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    margin: 5px 0 0;
    color: #ddd;
}

@media (max-width: 768px) {
    .service-item {
        width: 80%;
    }
}
