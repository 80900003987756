.footer {
    background-color: #2c2c2c;
    color: #fff;
    padding: 40px 0;
    position: relative;
    z-index: 1;
    text-align: center;
    width: 100%;
}

.footer::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 10px;
    background: linear-gradient(to right, #ff7e5f, #feb47b);
    z-index: -1;
}

.footer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    gap: 20px;
    flex-wrap: wrap;
    animation: fadeIn 1s ease-in-out;
}

.footer-section {
    flex: 1;
    min-width: 200px;
    text-align: center;
}

.logo-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footer-logo-image {
    max-width: 50px;
    margin-bottom: 10px;
}

.footer-logo-text {
    font-size: 1.8em;
    font-weight: bold;
    color: #ff0;
    transition: transform 0.3s ease;
}

.footer-logo-text:hover {
    transform: scale(1.1);
}

.footer-section h2 {
    font-size: 24px;
    margin-bottom: 20px;
    font-family: 'Nunito', sans-serif;
    color: #ffd700;
    position: relative;
    animation: slideIn 1s ease-out;
}

.footer-section p, .footer-section ul {
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    list-style: none;
}

.footer-section ul {
    padding-left: 0;
}

.footer-section ul li {
    margin-bottom: 10px;
    position: relative;
    animation: fadeInUp 0.5s ease-out;
}

.footer-section ul li a {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s ease;
}

.footer-section ul li a:hover {
    color: #ffd700;
}

.social-links {
    display: flex;
    justify-content: center;
    gap: 15px;
    font-size: 24px;
}

.social-links a {
    color: #fff;
    transition: color 0.3s ease, transform 0.3s ease;
}

.social-links a:hover {
    color: #ffd700;
    transform: translateY(-3px);
}

.footer-bottom {
    margin-top: 20px;
    font-size: 14px;
    color: #ccc;
    animation: fadeIn 2s ease-in-out;
}

@media (max-width: 768px) {
    .footer-container {
        flex-direction: column;
        align-items: center;
    }

    .footer-section {
        text-align: center;
    }

    .footer-logo {
        margin-bottom: 20px;
    }
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes slideIn {
    0% { transform: translateY(-20px); }
    100% { transform: translateY(0); }
}

@keyframes fadeInUp {
    0% { transform: translateY(20px); opacity: 0; }
    100% { transform: translateY(0); opacity: 1; }
}
