.privacy-policy {
    padding: 50px 20px;
    background-color: #363636e0; /* Match the background color of the footer */
    color: #ffffff;
    text-align: center;
    width: 90%;
    margin: 0 auto;
    border-radius: 15px;
    margin-bottom: 20px;
    margin-top: 20px; /* Added some margin at the top */
}

.privacy-policy-container {
    max-width: 800px;
    margin: 0 auto;
    text-align: left; /* Align text to the left for better readability */
}

.privacy-policy h1, .privacy-policy h2 {
    color: #ffd700; /* Gold color for headings */
    margin-bottom: 20px;
    font-family: 'Nunito', sans-serif;
}

.privacy-policy p, .privacy-policy ul {
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    color: #ddd;
    line-height: 1.6;
}

.privacy-policy ul {
    padding-left: 20px;
}

.privacy-policy ul li {
    list-style: disc;
    margin-bottom: 10px;
}

.privacy-policy-section {
    margin-bottom: 30px;
}

@media (max-width: 768px) {
    .privacy-policy {
        padding: 30px 10px;
    }

    .privacy-policy h1 {
        font-size: 24px;
    }

    .privacy-policy h2 {
        font-size: 20px;
    }

    .privacy-policy p, .privacy-policy ul {
        font-size: 14px;
    }
}
