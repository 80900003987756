/* Global styles */
body {
  margin: 0;
  padding: 0;
  font-family: 'Nunito', sans-serif;
  background-color: #000;
  color: rgb(255, 255, 255);
}

.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
}
