/* src/components/Pricing.css */

/* General Styles */
body {
  font-family: 'Roboto', sans-serif;
  background-color: #333;
  color: #fff;
  margin: 0;
  padding: 0;
}

.container {
  padding: 20px 8%;
}

.section.pricing {
  padding: 20px 8%; /* Reduced padding */
  text-align: center;
  background-color: #000; /* Black background */
  color: #fff;
}

.pricing-header h1 {
  font-size: 2em; /* Reduced font size */
  margin-bottom: 10px; /* Reduced margin */
  color: #fff; /* White color for header text */
}

p {
  font-size: 0.8em; /* Reduced font size */
  margin-bottom: 20px; /* Reduced margin */
  color: #b8b8b8; /* Light gray color for paragraph text */
}

.estimator {
  display: flex;
  justify-content: space-between; /* Align horizontally */
  align-items: center; /* Align vertically in the center */
  margin-top: 10px; /* Reduced margin */
  background-color: #3c3c3c; /* Dark background for the estimator section */
  padding: 10px; /* Reduced padding */
  border-radius: 8px;
}

.budget-input {
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.budget-input label {
  display: block;
  margin-bottom: 5px; /* Reduced margin */
  font-size: 1em; /* Reduced font size */
}

.budget-input input {
  width: 300px; /* Set a fixed width */
  padding: 8px; /* Reduced padding */
  margin-bottom: 10px;
  font-size: 1em;
  border: 1px solid #333;
  border-radius: 4px;
  background-color: #333; /* Dark background for input */
  color: #fff; /* White text color for input */
}

.budget-input .btn {
  padding: 8px 16px; /* Reduced padding */
  font-size: 1em;
  border: none;
  background-color: #ff0; /* Yellow background for button */
  color: Black;
  text-emphasis-color: Black;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.budget-input .btn:hover {
  background-color: #ffe100; /* Slightly darker yellow for hover effect */
}

.error-message {
  color: rgb(255, 255, 255);
  font-size: 0.9em;
}

.estimate-results {
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.estimate-results h3 {
  margin-bottom: 35px; /* Reduced margin */
  font-size: 1.5em; /* Reduced font size */
  color: #ffffff; /* White color for "Your Estimates" heading */
}

.estimate {
  display: flex;
  justify-content: space-between;
  width: 100%; /* Full width */
}

.estimate-item {
  margin: 8px;
  padding: 10px; /* Reduced padding */
  border: 1px solid #444;
  border-radius: 4px;
  width: 130px; /* Reduced width */
  height: 130px; /* Reduced height */
  background-color: #333333; /* Dark background for estimate items */
  color: #fff; /* White text color for estimate items */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.estimate-item h4 {
  margin-bottom: 5px; /* Reduced margin */
  font-size: 1em; /* Reduced font size */
  color: #ffffff; /* White color for estimate item headings */
}

.estimate-item p {
  font-size: 1em; /* Reduced font size */
  font-weight: bold;
  color: #ffe100; /* Yellow color for estimate values */
}

.estimate-item:hover {
  transform: translateY(-5px);
}

.proceed-button {
  margin-top: 20px;
}

.proceed-button .btn {
  padding: 8px 16px; /* Reduced padding */
  font-size: 1em;
  border: none;
  background-color: #ff0; /* Yellow background for proceed button */
  color: rgb(0, 0, 0);
  text-decoration-color: #3c3c3c;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.proceed-button .btn:hover {
  background-color: #ffe100; /* Slightly darker yellow for hover effect */
}

/* Mobile Styles */
@media (max-width: 768px) {
  .container {
    padding: 10px;
  }

  .pricing-header h1 {
    font-size: 1.5em; /* Adjusted for smaller screens */
  }

  p {
    font-size: 0.7em; /* Adjusted for smaller screens */
  }

  .estimator {
    flex-direction: column; /* Stack items vertically on smaller screens */
    padding: 5px;
  }

  .budget-input input {
    width: 100%; /* Full width for smaller screens */
    margin-bottom: 10px;
  }

  .budget-input .btn {
    width: 100%; /* Full width for smaller screens */
  }

  .estimate {
    flex-direction: column; /* Stack estimate items vertically */
    align-items: center;
  }

  .estimate-item {
    width: 100%; /* Full width for smaller screens */
    max-width: 300px; /* Max width to keep reasonable size */
    margin-bottom: 10px;
  }

  .proceed-button .btn {
    width: 100%; /* Full width for proceed button */
  }
}
