/* src/components/Navbar.css */

@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap');

.navbar {
    background-color: #333333;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    font-family: 'Nunito', sans-serif;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    font-size: 20px;
    width: 99%;
}

.navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.logo a {
    font-size: 1.5em;
    font-weight: bold;
    color: #ff0; /* Yellow color for logo */
    text-decoration: none;
    margin-right: 100px;
    transition: color 0.3s, transform 0.3s;
}

.logo a:hover {
    color: #ffe100;
    transform: scale(1.1);
}

.nav-links {
    list-style: none;
    display: flex;
    gap: 20px; /* Adjusted gap */
    margin: 0;
    flex: 1; /* Takes up remaining space between logo and auth-buttons */
    justify-content: center; /* Centers the nav links */
    align-items: center;
}

.nav-links li {
    position: relative;
}

.nav-links a {
    text-decoration: none;
    color: #fff;
    font-size: 1em;
    transition: color 0.3s, transform 0.3s;
    position: relative;
}

.nav-links a::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    display: block;
    margin-top: 5px;
    right: 0;
    background: #ff0;
    transition: width 0.4s ease, background-color 0.4s ease;
}

.nav-links a:hover::after {
    width: 100%;
    left: 0;
    background: #ffe100;
}

.nav-links a:hover {
    color: #ff0; /* Yellow color on hover */
}

.auth-buttons {
    display: flex;
    gap: 20px; /* Increased gap */
    margin-left: 100px; /* Add space between nav links and auth buttons */
    margin-right: 40px;
}

.signin, .signup {
    padding: 8px 16px; /* Adjusted padding */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
}

.signin {
    background-color: #555;
    color: #fff;
}

.signup {
    background-color: #ff0;
    color: #333;
}

.signin:hover {
    background-color: #666;
    transform: scale(1.05);
}

.signup:hover {
    background-color: #ffe100;
    transform: scale(1.05);
}

.toggle-button {
    display: none;
    flex-direction: column;
    justify-content: space-around;
    width: 30px;
    height: 21px;
    background: transparent;
    border: none;
    cursor: pointer;
    z-index: 1001;
}

.toggle-button .bar {
    width: 100%;
    height: 3px;
    background-color: #fff;
    border-radius: 10px;
    transition: transform 0.3s, opacity 0.3s;
}

.toggle-button.open .bar:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
}

.toggle-button.open .bar:nth-child(2) {
    opacity: 0;
}

.toggle-button.open .bar:nth-child(3) {
    transform: rotate(-45deg) translate(5px, -5px);
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropbtn {
    background-color: #333;
    color: white;
    padding: 10px;
    font-size: 1em; /* Adjusted font size */
    border: none;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
}

.dropbtn:hover, .dropbtn:focus {
    background-color: #555;
    transform: translateY(-2px);
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #444;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    border-radius: 4px;
    overflow: hidden;
    transition: all 0.3s;
}

.dropdown-content a {
    color: white;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    transition: background-color 0.3s;
}

.dropdown-content a:hover {
    background-color: #555;
}

.dropdown:hover .dropdown-content {
    display: block;
}

@media (max-width: 768px) {
    .nav-links, .auth-buttons {
        display: none;
    }

    .nav-links.open, .auth-buttons.open {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 60px;
        left: 0;
        width: 100%;
        background-color: #333;
        padding: 20px 0;
        gap: 10px;
        z-index: 1000;
    }

    .toggle-button {
        display: flex;
        margin-left: 20px;
    }

    .logo a {
        margin-left: 0; /* No padding for mobile */
    }
}

@media (min-width: 769px) {
    .logo a {
        margin-left: 80px; /* Add space for desktop */
    }
}
