.how-it-works {
    padding: 50px 20px;
    background-color: #3c3c3c; /* Different background color for distinction */
    color: #ffffff;
    text-align: center;
    width: 90%;
    margin: 0 auto;
    border-radius: 15px;
    margin-bottom: 20px;
    margin-top: 10px;
}

.how-it-works h2 {
    font-size: 36px;
    margin-bottom: 20px;
    font-family: 'Nunito', sans-serif;
    color: #ffffff; /* Explicitly set to white */
}

.how-it-works-description {
    font-size: 18px;
    margin-bottom: 40px;
    font-family: 'Roboto', sans-serif;
    color: #ddd;
    line-height: 1.6;
}

.how-it-works-steps {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}

.step {
    background-color: #2e2e2e;
    padding: 20px;
    border-radius: 15px;
    text-align: left;
    box-sizing: border-box;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.step:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.icon {
    font-size: 48px;
    margin-bottom: 20px;
    color: #ff0;
}

.step-content {
    text-align: center;
}

.step-content h3 {
    font-size: 24px;
    margin: 10px 0;
    font-family: 'Nunito', sans-serif;
    color: #fff;
}

.step-content p {
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    margin: 5px 0 0;
    color: #ddd;
}

@media (max-width: 768px) {
    .how-it-works-steps {
        grid-template-columns: 1fr;
    }

    .step {
        width: 100%;
        margin-bottom: 20px;
    }

    .how-it-works h2 {
        font-size: 28px;
    }

    .how-it-works-description {
        font-size: 16px;
        margin-bottom: 30px;
    }

    .icon {
        font-size: 36px;
    }

    .step-content h3 {
        font-size: 20px;
    }

    .step-content p {
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .how-it-works {
        padding: 30px 10px;
    }

    .how-it-works h2 {
        font-size: 24px;
    }

    .how-it-works-description {
        font-size: 14px;
    }

    .step {
        padding: 15px;
    }

    .icon {
        font-size: 30px;
    }

    .step-content h3 {
        font-size: 18px;
    }

    .step-content p {
        font-size: 12px;
    }
}
