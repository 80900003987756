/* Login.css */

body {
    background-color: #1a1a1a;
    font-family: 'Arial', sans-serif;
}

.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #1a1a1a;
    padding: 20px;
}

.login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #333;
    padding: 40px;
    border-radius: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
}

.login-form h2 {
    margin-bottom: 10px;
    font-size: 24px;
    color: #fff;
}

.login-form p {
    margin-bottom: 20px;
    font-size: 14px;
    color: #ccc;
}

.login-form label {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 15px;
    font-size: 14px;
    color: #fff;
}

.login-form input {
    width: 100%;
    padding: 12px;
    margin-left: 10px;
    margin-bottom: 15px;
    border: none;
    border-radius: 30px;
    font-size: 14px;
    color: #fff;
    background-color: #444;
}

.login-form button {
    width: 100%;
    padding: 12px;
    background-color: #f39c12;
    color: #fff;
    border: none;
    border-radius: 30px;
    font-size: 16px;
    cursor: pointer;
    margin-bottom: 20px; /* Increased spacing */
}

.login-form button:hover {
    background-color: #e67e22;
}

.google-login {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
}

.google-login .google-login-button {
    width: 100%;
    padding: 12px;
    background-color: #f39c12; /* Match the color of other buttons */
    color: #fff;
    border: none;
    border-radius: 30px;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.google-login .google-login-button:hover {
    background-color: #e67e22;
}

.login-footer {
    text-align: center;
    font-size: 14px;
    color: #ccc;
}

.login-footer a {
    color: #f39c12;
    text-decoration: none;
}

.login-footer a:hover {
    text-decoration: underline;
}
