/* src/components/Success.css */
.success-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full viewport height */
    background-color: #33333300; /* Dark background color */
    color: #fff; /* White text color */
}

.success-content {
    text-align: center;
    padding: 40px;
    background-color: #49505700; /* Darker background for content */
    border-radius: 8px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.69); /* Stronger shadow */
    max-width: 600px;
    width: 90%;
}

.success-content h1 {
    font-size: 3rem;
    color: #ffc107; /* Yellow title color */
    margin-bottom: 20px;
}

.success-text {
    font-size: 1.2rem;
    margin-bottom: 15px;
}

.contact-link {
    display: inline-block;
    font-size: 1.2rem;
    color: #ffc107; /* Yellow link color */
    text-decoration: none;
    margin-top: 15px;
}

.contact-link:hover {
    text-decoration: underline;
}

.contact-link svg {
    vertical-align: middle;
    margin-right: 5px;
}

/* Animation classes */
@keyframes bounceIn {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

.animate__animated {
    animation-duration: 1s;
    animation-fill-mode: both;
}

.animate__bounceIn {
    animation-name: bounceIn;
}
