/* General Styles */
body {
  background-color: #000;
  color: #fff;
  font-family: Arial, sans-serif;
}

.campaign-setup-page {
  padding: 20px;
  background-color: #000;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  background-color: #333;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
}

h1 {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
  color: #fff;
}

.input-field {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #555;
  border-radius: 4px;
  background-color: #444;
  color: #fff;
  box-sizing: border-box;
}

.input-field::placeholder {
  color: #aaa;
}

.error {
  border-color: red;
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: -10px;
  margin-bottom: 10px;
}

.video-upload {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.fetch-button {
  padding: 10px 20px;
  background-color: #ff0;
  color: #000;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.fetch-button:hover {
  background-color: #cc0;
}

.video-preview {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
  background-color: #444;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.1);
}

.video-thumbnail img {
  width: 120px;
  height: auto;
  border-radius: 4px;
}

.video-info {
  flex-grow: 1;
}

.preview {
  margin-top: 20px;
}

.campaign-summary {
  margin-top: 20px;
  background-color: #444;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.1);
}

.campaign-summary h2 {
  font-size: 20px;
  margin-bottom: 10px;
  color: #fff;
}

.campaign-summary p {
  margin: 5px 0;
  color: #fff;
}

.launch-button {
  padding: 10px 20px;
  background-color: #ff0;
  color: #000;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
  width: 100%;
  box-sizing: border-box;
}

.launch-button:hover {
  background-color: #cc0;
}

/* Mobile Styles */
@media (max-width: 600px) {
  .container {
    padding: 15px;
  }

  .video-upload {
    flex-direction: column;
    align-items: stretch;
  }

  .fetch-button {
    width: 100%;
    margin-top: 10px;
  }

  .video-preview {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .video-info {
    margin-top: 10px;
  }

  .launch-button {
    margin-top: 15px;
  }
}
