/* src/components/Blog.css */
.blog-container {
    background-color: #333;
    color: #fff;
    border-radius: 10px;
    padding: 20px;
    margin: 20px 0;
    text-align: center;
    width: 300px; /* Adjust width as needed */
    font-size: medium;
  }
  
  .blog-image {
    width: 100%;
    height: auto;
    border-radius: 10px 10px 0 0;
  }
  
  .blog-container h2 {
    margin-top: 10px;
  }
  
  .blog-container button {
    background-color: #ffcc00;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .blog-container button:hover {
    background-color: #ff9900;
  }
  
  .blog-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .main-page {
    text-align: center;
    padding: 20px;
  }
  