.about-us {
    background-color: #2c2c2c00;
    color: #fff;
    padding: 50px 20px;
    text-align: center;
    font-family: 'Nunito', sans-serif;
    min-height: 100vh;
}

.about-us-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #3c3c3c;
    border-radius: 15px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    animation: fadeIn 1s ease-in-out;
    overflow: hidden;
}

.about-us h1 {
    font-size: 36px;
    margin-bottom: 20px;
    color: #ff0;
    animation: slideIn 1s ease-out;
}

.about-us p {
    font-size: 18px;
    line-height: 1.6;
    color: #ddd;
    margin-bottom: 40px;
    animation: fadeInUp 1s ease-out;
}

.about-us-section {
    margin-bottom: 40px;
}

.about-us-section h2 {
    font-size: 28px;
    margin-bottom: 20px;
    color: #ffd700;
    animation: slideIn 1.2s ease-out;
}

.about-us-section p {
    font-size: 18px;
    line-height: 1.6;
    color: #ddd;
    animation: fadeInUp 1.2s ease-out;
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes slideIn {
    0% { transform: translateY(-20px); }
    100% { transform: translateY(0); }
}

@keyframes fadeInUp {
    0% { transform: translateY(20px); opacity: 0; }
    100% { transform: translateY(0); opacity: 1; }
}
