.call-to-action {
    padding: 50px 20px;
    background-color: #3c3c3c;
    color: #fff;
    text-align: center;
    width: 90%;
    margin: 50px auto;
    border-radius: 15px;
}

.call-to-action h2 {
    font-size: 36px;
    margin-bottom: 20px;
    font-family: 'Nunito', sans-serif;
}

.call-to-action p {
    font-size: 18px;
    margin-bottom: 40px;
    font-family: 'Roboto', sans-serif;
    color: #ddd;
}

.cta-button {
    padding: 15px 30px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-family: 'Nunito', sans-serif;
    background-color: #ff0;
    color: #000;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.cta-button:hover {
    background-color: #ffd700;
    transform: scale(1.05);
}
