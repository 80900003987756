body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #121212; /* Dark gray background */
  }
  
  .howitworks-hero {
    background-color: #33333300; /* Darker gray */
    color: #fff;
    padding: 70px 20px;
    text-align: center;
  }
  
  .howitworks-hero .container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .howitworks-hero h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .howitworks-hero p {
    font-size: 1.25rem;
  }
  
  .process {
    padding: 50px 20px;
    background-color: #1e1e1e; /* Slightly lighter dark gray for contrast */
  }
  
  .process .container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .step {
    margin-bottom: 40px;
    padding: 20px;
    background: #2b2b2b; /* Slightly lighter dark gray */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: flex-start;
  }
  
  .step .icon {
    font-size: 2rem;
    color: #ffcc00; /* Yellow icon */
    margin-right: 20px;
  }
  
  .step h2 {
    color: #fff;
    font-size: 1.75rem;
    margin-bottom: 15px;
  }
  
  .step h3 {
    color: #ffcc00; /* Yellow subheading */
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .step p {
    color: #ccc; /* Light gray text */
    font-size: 1rem;
    line-height: 1.6;
  }
  
  .cta {
    background-color: #333;
    color: #fff;
    padding: 50px 20px;
    text-align: center;
  }
  
  .cta .container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .cta h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .cta p {
    font-size: 1.25rem;
    margin-bottom: 30px;
  }
  
  .cta-button {
    background-color: #ffcc00; /* Yellow button */
    color: #333; /* Dark text on yellow button */
    border: none;
    padding: 15px 30px;
    font-size: 1.25rem;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
    text-decoration: none;
  }
  
  .cta-button:hover {
    background-color: #e6b800; /* Darker yellow on hover */
  }
  