.contact-us {
    background-color: #333;
    color: #fff;
    padding: 50px 20px;
    text-align: center;
    font-family: 'Nunito', sans-serif;
}

.contact-us-container {
    max-width: 600px;
    margin: 0 auto;
    background-color: #3c3c3c;
    padding: 20px;
    border-radius: 15px;
}

.contact-us h2 {
    font-size: 36px;
    margin-bottom: 20px;
    color: #ff0;
}

.contact-us p {
    font-size: 18px;
    margin-bottom: 40px;
    color: #ddd;
}

.contact-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.contact-form label {
    font-size: 18px;
    text-align: left;
    margin-bottom: 5px;
}

.contact-form input,
.contact-form textarea {
    padding: 10px;
    border: 1px solid #444;
    border-radius: 5px;
    background-color: #222;
    color: #fff;
    font-size: 16px;
}

.contact-form input:focus,
.contact-form textarea:focus {
    border-color: #ff0;
    outline: none;
}

.submit-button {
    padding: 10px 20px;
    background-color: #ff0;
    color: #333;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.submit-button:hover {
    background-color: #ffe100;
}

@media (max-width: 768px) {
    .contact-us h2 {
        font-size: 28px;
    }

    .contact-us p {
        font-size: 16px;
    }

    .contact-form label {
        font-size: 16px;
    }

    .contact-form input,
    .contact-form textarea {
        font-size: 14px;
    }

    .submit-button {
        font-size: 16px;
    }
}
