.testimonials {
    padding: 50px 20px;
    background-color: #2e2e2e;
    color: #fff;
    text-align: center;
    width: 90%;
    margin: 0 auto;
    border-radius: 15px;
}

.testimonials h2 {
    font-size: 36px;
    margin-bottom: 40px;
    font-family: 'Nunito', sans-serif;
}

.testimonial-items {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 20px;
}

.testimonial-item {
    background-color: #3c3c3c;
    padding: 20px;
    border-radius: 15px;
    width: 30%; /* Adjust the width of testimonial items */
    height: auto; /* Adjust the height as needed */
    text-align: center;
    box-sizing: border-box;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.testimonial-item:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.testimonial-image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-bottom: 10px;
}

.testimonial-content h3 {
    font-size: 24px;
    margin: 10px 0;
    font-family: 'Nunito', sans-serif;
}

.testimonial-content p {
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    color: #ddd;
    margin: 5px 0 0;
}

@media (max-width: 768px) {
    .testimonial-item {
        width: 80%;
    }
}
