/* GoogleSignIn.css */

.google-login {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
}

.google-login-button {
    width: 100%;
    padding: 12px;
    background-color: #4285f4; /* Google blue */
    color: #fff;
    border: none;
    border-radius: 30px;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow */
}

.google-logo {
    width: 20px; /* Adjust the size of the Google logo */
    height: 20px;
    margin-right: 10px; /* Space between logo and text */
}

.google-button-text {
    margin-left: 5px; /* Adjust spacing between logo and text */
}

.google-login-button:hover {
    background-color: #1967d2; /* Darker blue on hover */
}
