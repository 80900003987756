.statistics {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 50px 20px;
    width: 90%; /* Take 90% of the width */
    margin: 0 auto; /* Center horizontally */
    flex-wrap: wrap; /* Allow items to wrap on smaller screens */
    gap: 20px; /* Add space between stat items */
    background-color: transparent; /* Remove background color */
}

.stat-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    border-radius: 15px;
    width: 22%; /* Adjust the width of stat items */
    height: 100px; /* Set a fixed height */
    margin: 10px;
    background-color: #3c3c3c;
    box-sizing: border-box;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.stat-item:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.stat-text {
    margin-left: 10px;
}

.stat-item h2 {
    font-size: 24px;
    margin: 0;
    font-family: 'Nunito', sans-serif;
}

.stat-item p {
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    margin: 5px 0 0;
}

.stat-icon {
    width: 50px;
    height: auto;
}

@media (max-width: 768px) {
    .statistics {
        flex-direction: column;
        align-items: center;
    }

    .stat-item {
        width: 80%;
    }
}
