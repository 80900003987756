/* src/components/ExpertReviewPage.css */

.expert-review-page {
  padding: 40px 20px; /* Increased padding for more space */
  text-align: center;
  background-color: #33333300; /* Dark background */
  color: #fff;
}

.expert-review-banner {
  position: relative;
  background-image: url('../assets/seo.webp'); /* Adjust path relative to your project structure */
  background-size: cover;
  background-position: center;
  padding: 80px 40px;
  color: #fff;
  border-radius: 8px;
  margin-bottom: 40px; /* Increased margin for better spacing */
  max-width: 800px; /* Adjust the max-width as needed */
  margin: 0 auto; /* Center the banner */
  font-size: 1.5rem; /* Adjust font size for better readability */
}

.expert-review-banner::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  border-radius: 8px;
}

.expert-review-banner h1,
.expert-review-banner p {
  position: relative;
  z-index: 1;
}

.expert-review-banner h1 {
  font-size: 2.5rem; /* Larger font size for heading */
  margin-bottom: 20px;
}

.expert-review-banner p {
  font-size: 1.2rem; /* Adjust font size for paragraph */
  margin-bottom: 30px;
}

.channel-form {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: relative;
  z-index: 1;
  margin-top: 20px; /* Space between banner and form */
}

.channel-form input {
  flex: 1;
  max-width: 300px; /* Set max width */
  padding: 12px;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  background-color: #fff;
  color: #333; /* Dark text color */
}

.channel-form button {
  padding: 12px 24px;
  border: none;
  background-color: #f0ad4e; /* Yellow button */
  color: #fff;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.channel-form button:hover {
  background-color: #ec971f; /* Darker shade of yellow on hover */
}

.expert-review-message {
  margin-top: 20px;
  padding: 20px;
  background-color: #444; /* Darker background for message */
  border-radius: 8px;
  color: #fff;
  font-size: 1rem;
}

/* Ensure responsiveness */
@media (max-width: 768px) {
  .expert-review-banner h1 {
    font-size: 2rem; /* Adjust font size for smaller screens */
  }

  .expert-review-banner p {
    font-size: 1rem; /* Adjust font size for smaller screens */
  }

  .channel-form {
    flex-direction: column; /* Stack form elements vertically on small screens */
  }

  .channel-form input,
  .channel-form button {
    width: 100%; /* Full width for form elements on small screens */
  }
}
